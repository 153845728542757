import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import NavBar from "./NavBar";
import Footer from "./Footer";
import ImageWall from "./ImageWall";
import Container from "./Container";
import ContainerContent from "./ContainerContent";
import ContainerHeader from "./ContainerHeader";
import SeoContent from "./seoContent";
import BookNow from "./BookNow";
import Accommodation from "../templates/accommodation/accommodation";

const Layout = () => {
  const query = graphql`
  query {
    allStrapiAccommodation {
      edges {
        node {
          RoomName
          Slug
          BookNowLink
          Images {
            id
            alternativeText
            localFile {
              childImageSharp {
                fixed(width: 800, height: 534) {
                  src
                }
              }
            }
            alternativeText
          }
          MainImage {
            alternativeText
            localFile {
              childImageSharp {
                fixed(width: 1920, height: 1080) {
                  src
                }
              }
            }
          }
          ShowMainImage
          BookNowLink
          Description
          Seo {
            metaDescription
            metaTitle
            shareImage {
              localFile {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
    strapiHomepage {
      Seo {
        metaDescription
        metaTitle
        shareImage {
          localFile {
            childImageSharp {
              fluid {
                src
              }
            }
          }
        }
      }
      MainImage {
        alternativeText
        localFile {
          childImageSharp {
            fixed(width: 1920, height: 1080) {
              src
            }
          }
        }
      }
      MainBodyTitle
      MainBody
      MainBodyImageWall {
        alternativeText
        id
        localFile {
          childImageSharp {
            fixed {
              src
            }
          }
        }
      }
      ImageWallTwo {
        alternativeText
        id
        localFile {
          childImageSharp {
            fixed {
              src
            }
          }
        }
      }
    }
  }  
  `;

  const data = useStaticQuery(query);

  const isSingleSiteEnvSetting = process.env.IS_SINGLE_PAGE_SITE;

  const isSingleSite = isSingleSiteEnvSetting === "true";

  if (isSingleSite === true) {
    const siteName = process.env.SINGLE_PAGE_NAME;

    var result = data.allStrapiAccommodation.edges.find(e => e.node.Slug === siteName).node

    var pageData = {
      strapiAccommodation: result
    }

    return <Accommodation data={pageData} />;
  }

  return (
    <>
      <SeoContent pageSeo={data.strapiHomepage.Seo} />
      <div className="overflow-hidden">
        <div className="leading-relaxed bg-primary tracking-wide flex flex-col font-custom">
          <NavBar isSingleSite={isSingleSite}/>
          <Container>
            <img
              src={
                data.strapiHomepage.MainImage.localFile.childImageSharp.fixed
                  .src
              }
              className="w-full"
              alt={data.strapiHomepage.MainImage.alternativeText}
            />
          </Container>
          <Container>
            <ContainerHeader text={data.strapiHomepage.MainBodyTitle} />
            <ContainerContent text={data.strapiHomepage.MainBody} />
            <BookNow bookNowLink={data.strapiHomepage.BookNowLink} />
            <ImageWall
              images={data.strapiHomepage.MainBodyImageWall}
              keyPrefix="home"
            />
          </Container>
          <Container>
            <ImageWall
              images={data.strapiHomepage.ImageWallTwo}
              keyPrefix="home-two"
            />
          </Container>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Layout;
