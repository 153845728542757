import * as React from "react";
import Layout from "../components/Layout.js";

const IndexPage = () => {
  return (
    <>
      <Layout></Layout>
    </>
  );
};

export default IndexPage;
